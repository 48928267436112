<template>
  <div class="individual-list">
    <m-resource-top-sheet
      :columns="columns"
      title="Individuals"
    >
      <template slot="tools">
        <div class="row tools q-col-gutter-sm">
          <div class="col-sm-4 gt-sm">
            <q-select
              v-model="filters.blocked"
              :options="block_statuses"
              label="Status"
              emit-value
              map-options
              color="primary"
            >
              <template v-slot:option="scope">
                <q-item
                  v-bind="scope.itemProps"
                  v-on="scope.itemEvents"
                >
                  <q-item-section>
                    <q-item-label label>
                      {{ scope.opt.label }}
                    </q-item-label>
                  </q-item-section>
                </q-item>
              </template>
            </q-select>
          </div>
          <div class="col-xs-12 col-sm-8">
            <q-input
              v-model="searchTerm"
              label="Search for an individual"
              class="search"
              debounce="500"
              color="primary"
              @input="runSearch"
            />
          </div>
        </div>
      </template>
    </m-resource-top-sheet>
    <div class="row index-list">
      <m-infinite-scroll
        :load="filter"
        :page="filters.page"
        :pagination="pagination"
        icon="person"
        resource="individuals"
      >
        <div
          v-for="i in individuals"
          :key="i.username"
          class="row-item"
        >
          <individual-card
            :individual="i"
            :selected="selected"
          />
        </div>
      </m-infinite-scroll>
    </div>
  </div>
</template>

<script type="text/javascript">
import _ from 'lodash'
import store from 'store'
import mInfiniteScroll from 'components/MInfiniteScroll'
import { MResourceTopSheet } from 'components/'
import individualCard from './individual-card'
import authentication from 'mixins/authentication'
export default {
  name: 'Individuals',
  components: {
    mInfiniteScroll,
    individualCard,
    MResourceTopSheet
  },
  mixins: [authentication],
  props: {
    small: {
      type: Boolean
    },
    selected: {
      type: String
    }
  },
  data () {
    return {
      searchTerm: '',
      individuals: [],
      block_statuses: [],
      filters: {
        page: 0,
        limit: 10,
        search: '',
        blocked: false
      },
      pagination: null,
      columns: ['Name', 'Email', 'Status']
    }
  },
  computed: {
    consumerToken: () => store.getters['partner/consumerToken']
  },
  watch: {
    'filters.blocked' () {
      this.clear()
    },
    consumerToken: {
      deep: true,
      handler () {
        this.clear()
        this.filter()
      }
    }
  },
  created () {
    store.dispatch('individuals/reset')
    this.block_statuses = this.$store.getters['individuals/getSelect']('blockStatuses')
  },
  methods: {
    clear () {
      this.filters.page = 0
      this.individuals = []
    },
    runSearch (search) {
      if (search.length || (!search.length && this.filters.search.length)) {
        this.clear()
        this.filters.search = search
      }
    },
    filter (page) {
      this.filters.page = page
      return store.dispatch('individuals/loadUsers', {
        params: _.pickBy(this.filters, val => val !== null && val !== '')
      })
        .then(response => {
          this.pagination = response.meta.pagination
          this.individuals = this.individuals.concat(response.data)
        })
    }
  }
}
</script>

<style lang="stylus" scoped>
.index-list
  height calc(100vh - 185px)
  @media (min-width 768px)
    padding 0 3rem
    height calc(100vh - 271px)

  .row-item:first-child
    margin-top 16px

.index-head, .resource-card
  @media (min-width 768px)
    width 75%

.resource-card
  max-height 82px
  position relative

.row-item
  width 100%

.tools
  @media (min-width: 768px)
    width 50%

.search
  margin-bottom 10px
  @media (min-width: 768px)
    margin-bottom 0px

</style>
