<template>
  <q-card
    v-ripple
    :class="selected === individual.username ? 'bg-deep-purple-4 text-white shadow-3' : 'bg-white shadow-1'"
    class="cursor-pointer individual"
    @click.native="openUserPeek()"
  >
    <q-card-section class="row">
      <div class="gt-xs col-sm-5 col-md-4 tcell">
        <img :src="individual.picture" class="avatar small-hide">
        <div>{{ individual.display_name }}</div>
      </div>
      <div class="lt-sm col-xs-4 tcell">
        {{ individual.display_name }}
      </div>
      <div class="gt-sm col-sm-4 tcell">
        <span>{{ individual.email }}</span>
      </div>
      <div class="col-xs-8 col-sm-4 tcell small-hide row q-col-gutter-sm">
        <div class="col-xs-6 col-sm-5">
          <span><q-chip
            :small="true"
            class="text-white"
            :color="individual.is_blocked ? 'negative' : 'positive'"
          >{{ individual.is_blocked ? 'Blocked' : 'Active' }}</q-chip></span>
        </div>
        <div class="col-xs-6 col-sm-7 actions" @click.stop>
          <q-btn outline label="view" @click.stop="view('profile')" />
          <div class="individual-menu">
            <q-btn
              :class="'text-primary'"
              flat
              round
              dense
              icon="more_vert"
              @click.stop="showPopover = !showPopover"
            />
            <q-menu v-model="showPopover">
              <q-list
                link
                style="min-width: 100px"
              >
                <q-item
                  v-for="item in menuItems"
                  :key="item.label"
                  v-close-popup
                  v-ripple
                  clickable
                  @click.native="view(item.tab)"
                >
                  <q-item-section>{{ item.label }}</q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </div>
        </div>
      </div>
    </q-card-section>
  </q-card>
</template>

<script>
export default {
  props: {
    individual: Object,
    selected: String
  },
  data () {
    return {
      showPopover: false,
      menuItems: [
        {
          label: 'Bookings',
          tab: 'bookings'
        },
        {
          label: 'Journeys',
          tab: 'journeys'
        },
        {
          label: 'Invoices',
          tab: 'invoices'
        },
        {
          label: 'Notes',
          tab: 'notes'
        },
        {
          label: 'History',
          tab: 'history'
        }
      ]
    }
  },
  methods: {
    view (tab) {
      this.$router.push(`/individuals/${this.individual.username}/${tab}`)
    },
    openUserPeek () {
      this.$router.push({ hash: '/user-settings', query: { username: this.individual.username } })
    }
  }
}
</script>

<style lang="stylus" scoped>
.tcell
  display: flex;
  align-items: center;
  justify-content: flex-start;

.avatar
  margin-right 15px

.individual-menu
  position: absolute;
  top: 0px;
  right: 12px;

.actions
  height 50px

.individual
  position relative
  @media (min-width 1024px)
    width 75%

.small-individual-list
  .individual
    width 96%
  .small-hide
    display none
  .individual
    max-height 70px
</style>
