<template>
  <m-invoices
    :load="load"
    :user="username"
    :reset-items="() => {$store.dispatch('entities/invoices/deleteAll')}"
    height="calc(100vh - 378px)"
  />
</template>

<script>
import { MInvoices } from 'components/'

export default {
  components: { MInvoices },
  data () {
    return {
      username: null
    }
  },
  beforeMount () {
    this.username = this.$route.params.username
  },
  methods: {
    async load (filters) {
      return this.$store.dispatch('entities/invoices/loadIndividualInvoices', {
        username: this.username,
        params: filters
      })
    }
  }
}
</script>
