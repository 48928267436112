<template>
  <div
    v-if="round"
    class="container"
  >
    <q-btn
      :class="`button text-${color || 'white'}`"
      round
      dense
      icon="cached"
      style="margin-right: 15px;"
      @click.stop="individualResetPassword()"
    >
      <q-tooltip>
        Reset Password
      </q-tooltip>
    </q-btn>
    <q-btn
      v-if="!individual.is_blocked"
      :class="`button text-${color || 'white'}`"
      round
      dense
      icon="block"
      style="margin-right: 15px;"
      @click.stop="individualBlock()"
    >
      <q-tooltip>
        Block
      </q-tooltip>
    </q-btn>
    <q-btn
      v-if="individual.is_blocked"
      :class="`button text-${color || 'white'}`"
      round
      dense
      icon="check_circle_outline"
      style="margin-right: 15px;"
      @click.stop="individualUnblock()"
    >
      <q-tooltip>
        Unblock
      </q-tooltip>
    </q-btn>
    <q-btn
      :class="`button text-${color || 'white'}`"
      round
      dense
      icon="settings"
      style="margin-right: 15px;"
      @click.stop="$router.push({ hash: '/user-settings', query: { username: individual.username } })"
    >
      <q-tooltip>
        Account Settings
      </q-tooltip>
    </q-btn>
  </div>
  <div
    v-else
    class="container labelled"
  >
    <q-btn
      :class="`text-${color || 'white'}`"
      color="light"
      label="Reset Password"
      style="margin-top: 15px;"
      @click.stop="individualResetPassword()"
    />
    <q-btn
      v-if="!individual.is_blocked"
      :class="`text-${color || 'white'}`"
      color="light"
      label="Block Individual"
      style="margin-top: 15px;"
      @click.stop="individualBlock()"
    />
    <q-btn
      v-if="individual.is_blocked"
      :class="`text-${color || 'white'}`"
      color="light"
      label="Unblock Individual"
      style="margin-top: 15px;"
      @click.stop="individualUnblock()"
    />
  </div>
</template>

<script type="text/javascript">
export default {
  props: {
    individual: {
      type: Object
    },
    color: {
      type: String
    },
    round: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    individualUnblock () {
      this.$q.dialog({
        title: 'Confirm',
        message: 'Are you sure you want to unblock this individual',
        ok: 'Confirm',
        cancel: 'Cancel'
      })
        .onOk(() => {
          this.$store.dispatch('individuals/unblockUser', {
            user: this.individual
          })
        })
    },
    individualBlock () {
      this.$q.dialog({
        title: 'Confirm',
        message: 'Are you sure you want to block this individual',
        ok: 'Confirm',
        cancel: 'Cancel'
      })
        .onOk(() => {
          this.$store.dispatch('individuals/blockUser', {
            user: this.individual
          })
        })
    },
    individualResetPassword () {
      this.$q.dialog({
        title: 'Confirm',
        message: "Are you sure you want to reset this member's password",
        ok: 'Confirm',
        cancel: 'Cancel'
      }).onOk(() => {
        this.$store.dispatch('members/resetUserPassword', {
          user: this.individual
        })
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
.container
  display inline-block
.labelled
  display flex
  flex-direction column
  justify-content center
  align-items center
  .q-btn
    display block
    width 182px
.button
  margin-top 10px
</style>
