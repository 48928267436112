<template>
  <m-journey-card-list
    :journeys-user="$route.params.username"
    class="layout-padding"
  />
</template>

<script>
import { MJourneyCardList } from 'components/'
export default {
  components: { MJourneyCardList }
}
</script>
